#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 100rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.introContent {
    height: 100vh;
    width: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.introName {
    color: #333333;
}

.introPara {
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;

}

.bg {
    position: absolute;
    top: 0;
    right: -4rem;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
}

.greeting {
    font-size: 1.75rem;
    font-weight: 100;
}

.btn {
    background: #fff;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.btn:active {
    opacity: 0;
    color: #fff;
    transition: opacity 0.2s, transform 0.2s;
}

.btn::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
}

.btn:hover {
    gap: 2rem;
    transform: scale(1.10);
    transition: 0.3s;
}

.btnImg {
    object-fit: cover;
    margin: 0 0.35rem;
    height: 1rem;
}

@media screen and (max-width:480px) {
    .bg {
        right: -20vw;
    }

}


