.graphics-page {
    font-family: Arial, sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
}

.graphics-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 2rem 1rem;
    background-color: #f59d39;
    width: 100%;
}

.graphics-header h1 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: bold;
}

.graphics-header h2 {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 1rem;
    font-weight: bold;
}

.graphics-header p {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 1rem;

}


.vector-container {
    align-self: flex-end;
}

.vector-img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem;
    margin-right: 15rem;
    position: relative;
    animation: move 5s infinite;
}

.vector-img:hover {
    animation-play-state: paused;
}

@keyframes move {
    from {left: 0;}
    to {left: 200px;}
}

.designer-intro {
    width: 100%;
    height: 100%;
    background-color: #96f3ed;
    padding: 5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.speech {
    text-align: center;
}

.speech p {
    text-align: justify;
    font-weight: bold;
    line-height: 2;
    font-size: 1.2rem;
    margin: 0 auto;
}

.contact-button {
    margin-top: 2rem;
    padding: 0.70rem 1rem;
    font-size: 0.9rem;
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: -60rem;
}

.contact-button:hover {
    background-color: #000;
    color: #fff;
}

.graphics-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #fff3cd;
    /* text-align: left; */
}

.graphics-skills h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.graphics-skills p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.graphics-skills ul {
    list-style-type: disc;
    margin-left: 2rem;
}

.graphics-skills ul li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.graphics-main {
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 3rem;
}

.graphics-main span {
    margin: 0 0.9rem;
    color: #f59d39;
    letter-spacing: 1px;
}

.graphics-resources p {
    margin-bottom: 0px;
    text-align: center;
}

.graphics-logo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0;
    /* margin: 5px; */
    margin: 50px auto;
    max-width: 800px;
}

.logos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
    width: 70px;
    height: 70px;
    animation: logoAnimation 2s ease infinite;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 10%;
    cursor: pointer;
}

@keyframes logoAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    0% {
        transform: translateY(0);
    }
}

@media (min-width: 768px) {
    .graphics-header {
        flex-direction: row;
        text-align: left;
        align-items: center;
    }

    .graphics-header h1,
    .graphics-header h2,
    .graphics-header p {
        margin-left: 10rem;
        flex: 1;
    }

    .vector-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .vector-img {
        max-width: 80%;
    }
}


.project-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    max-width: 800px; 
}

.project-head {
    font-size: 2rem;
    margin-bottom: 10px;
}

.project-desc {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .project-head {
        font-size: 1.5rem;
    }
    
    .project-desc {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .project-head {
        font-size: 1.2rem;
    }
    
    .project-desc {
        font-size: 0.9rem;
    }
}


.graphics-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
}

.graphics-item {
    max-width: 400px;
    margin: 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.graphics-item:hover {
    transform: translateY(-10px);
}

.graphics-item img {
    width: 400px;
    height: 300px;
    object-fit: cover;
    display: block;
}

.graphics-description {
    padding: 1rem;
    background-color: #fff;
}

.graphics-description h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.graphics-description p {
    font-size: 1rem;
    color: #666;
}


/* Project Intro Section */
.project-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    max-width: 800px; /* Optional: To constrain the width */
}

.project-head {
    font-size: 2rem;
    margin-bottom: 10px;
}

.project-desc {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

/* Contact Section */
.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 20px;
    background-color: #f9f9f9; /* Optional: To give the section a background color */
}

.contact-container {
    max-width: 900px; /* Optional: To constrain the width */
}

.contact-head {
    font-size: 2rem;
    margin-bottom: 10px;
}

.contact-desc {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.contact-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007BFF; /* Button background color */
    color: #fff;
    border: none;
    align-items: center;
    margin: 0 auto;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #0056b3; /* Button hover color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .project-head, .contact-head {
        font-size: 1.5rem;
    }

    .project-desc, .contact-desc {
        font-size: 1rem;
    }

    .contact-button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .project-head, .contact-head {
        font-size: 1.2rem;
    }

    .project-desc, .contact-desc {
        font-size: 0.9rem;
    }

    .contact-button {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
}
