#contactPage {
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPageTitle {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 50rem;
    margin: 0.5rem;
    padding: 0.5rem;
    color: #000;
    border: none;
    border-radius: 0.5rem;
    background: #8d8c8c;
}

/* .submitBtn {
    background: #8d8c8c;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
} */

/* .submitBtn {
    background: #8d8c8c;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .submitBtn:active {
    animation: popOut 0.4s ease-in-out;
  }
  

@keyframes popOut {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  } */

  .submitBtn {
    position: relative;
    background: #8d8c8c;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
    box-shadow: 3px 5px 7px rgb(0,0,0,0.5);
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
  }
  
  .submitBtn:after {
    content: '\2713'; /* Unicode for checkmark */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    font-size: 1.5rem;
    color: white;
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
  }
  
  .submitBtn:active:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }

  
  .submitBtn.submitted:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
    transition: opacity 0.2s, transform 0.2s;
  }
  
  

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
}

@media screen and (max-width:515px) {
    #contactPage {
        margin-top: 160rem;
    }
}