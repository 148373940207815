/* General styles for the Hire Me page */
.hire-me {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.hire-me-header {
    text-align: center;
    margin-bottom: 40px;
}

.hire-me-header h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.hire-me-header p {
    font-size: 18px;
    color: #666;
}

.services {
    margin-bottom: 40px;
}

.services h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.services ul {
    list-style-type: none;
    padding: 0;
}

.services ul li {
    background: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 18px;
}

.contact-form {
    margin-bottom: 40px;
}

.contact-form h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    margin-bottom: 15px;
    font-size: 16px;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form textarea {
    resize: vertical;
    height: 100px;
}

.contact-form button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.hire-me-footer {
    text-align: center;
    margin-top: 40px;
}

.hire-me-footer p {
    font-size: 16px;
    color: #666;
}

.hire-me-footer ul {
    list-style-type: none;
    padding: 0;
}

.hire-me-footer ul li {
    display: inline;
    margin: 0 10px;
}

.hire-me-footer ul li a {
    text-decoration: none;
    color: #007BFF;
    transition: color 0.3s;
}

.hire-me-footer ul li a:hover {
    color: #0056b3;
}