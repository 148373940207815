/* modal styles */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backdrop img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border: 3px solid #fff;
}

/* Responsive styles */
@media (max-width: 768px) {
    .backdrop img {
        max-width: 80%;
        max-height: 70%;
    }
}
