/* photograph.css */

.photo {
    position: relative;
    width: 100%;
    height: 100vh; /* Ensures the section covers the full height of the viewport */
    overflow: hidden;
}

.photoContent {
    position: relative;
    width: 100%;
    height: 100%;
}

.photoBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire section */
    z-index: -1; /* Places the image behind the heading */
}

.photoHeader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Places the heading in front of the image */
    color: #000; /* Adjust the color as needed */
    text-align: center;
    letter-spacing: 0.5rem;
    font-size: 2em; /* Adjust the font size as needed */
    padding: 0 20px; /* Adds some padding to prevent text from touching edges on smaller screens */
}

/* .scrollContent {
    padding: 50px 20px; 
    text-align: left; 
    background-color: #f0f0f0; 
} */

.scrollContent {
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px; 
    text-align: left; 
    min-height: 100vh; 
}

.textAndImage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    margin-bottom: 2rem;
}

.textAndImages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    margin-bottom: 2rem;
    max-width: 90rem;
}

.textAndImagesRep {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    margin-bottom: 2rem;
    max-width: 90rem;
}

.textAndImagesRep,textAndImages :nth-child(even) {
    flex-direction: row-reverse; 
}

.textContents {
    flex: 1;
    padding-right: 20px;
}

.textContentsRep {
    flex: 1;
    padding-right: 20px;
    margin-left: 15rem;
}

.textContent {
    flex: 1;
    padding-right: 20px;
    background-color: #f9f9f9;
}

.imageContent {
    flex: 1;
    text-align: right; /* Aligns image to the right */
}

.sideImage {
    object-fit: cover;
    max-width: 100%;
    height: 45rem;
    width:37rem;
    padding-top: 4rem;
}

.sideImageRep {
    object-fit: cover;
    max-width: 100%;
    height: 45rem;
    width:37rem;
    padding-top: 4rem;
    margin-right: 7rem;
}

.scrollHeader {
    font-size: 2.5rem;
    text-align: left;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
    margin-left: 1.4rem;
    letter-spacing: 0.1rem;
}

.scrollText {
    font-weight: 400;
    font-size: 1rem;
    max-width: 65rem;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
    letter-spacing: 1.5px;
}

.sideHandImage {
    object-fit: cover;
    height: 20rem;
    width: 30rem;
    margin-top: -5rem;
    margin-right: 13rem;
}

.textContent h3  {
    font-size: 2rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .photoHeader,
    .scrollHeader {
        font-size: 1.75em;
    }
    .scrollText {
        font-size: 1.15em;
    }
    .bookNowBtn {
        font-size: 0.9rem;
    }
}

@media (max-width: 992px) {
    .photoHeader,
    .scrollHeader {
        font-size: 1.5em;
    }
    .scrollText {
        font-size: 1.1em;
    }
    .sideHandImage {
        margin-right: 0;
        margin-top: 0;
    }
    .bookNowBtn {
        font-size: 0.85rem;
    }
}

@media (max-width: 768px) {
    .photoHeader,
    .scrollHeader {
        font-size: 1.25em;
    }
    .scrollText {
        font-size: 1em;
    }
    .sideHandImage {
        width: 100%;
        height: auto;
    }
    .bookNowBtn {
        font-size: 0.8rem;
    }
}

@media (max-width: 576px) {
    .photoHeader,
    .scrollHeader {
        font-size: 1em;
    }
    .scrollText {
        font-size: 0.9em;
    }
    .textAndImage,
    .textAndImages,
    .textAndImagesRep {
        flex-direction: column; /* Stacks text and image vertically on small screens */
        align-items: center;
    }
    .textAndImages,
    .textAndImagesRep {
        flex-direction: column; /* Stacks text and image vertically on small screens */
        align-items: center;
    }
    .textContent {
        padding-right: 0;
        text-align: center; /* Centers text on small screens */
    }
    .imageContent {
        text-align: center; /* Centers image on small screens */
    }
    .sideHandImage {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-top: 0;
    }
    .bookNowBtn {
        font-size: 0.75rem;
        padding: 10px 20px;
    }
}


.skill {
    max-width: 90rem;
    margin: 0 auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px;
    border: 1px solid #000;
    margin-top: 5rem;
}

.skill-title {
    font-size: 2.5rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 2rem;
    margin-left: 1.4rem;
    letter-spacing: 0.1rem;
    text-align: center; 
}

.skill-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px; 
    width: 100%;
    max-width: 70rem;
    letter-spacing: 0.05rem;
}

.skill-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.skill-header {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.skill-no {
    font-size: 1.25rem;
    font-weight: 700;
    color: #007BFF;
    margin-bottom: 10px;
}

.skill-para {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .skill-content {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
    }

    .skill-msg {
        align-items: center;
        text-align: center;
    }
}

.blues {
    max-width: 100%;
    margin: 0 auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 20px;
    margin-top: 5rem;
    background: #b8d1db;
}



.bookNowBtn {
    display: inline-block;
    padding: 12px 24px;
    margin-top: 20px;
    margin-left: 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #f9f9f9;
    background-color: #000;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.bookNowBtn:hover {
    background-color: #f9f9f9;
    color: #000;
}

.photos-only {
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    padding: 5rem;
    border: 1px solid #000;
    margin-top: 10rem;
    margin-bottom: 5rem;
}

.photonly {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .photonly {
        font-size: 2rem;
    }
}

@media (max-width: 992px) {
    .photonly {
        font-size: 1.75rem;
    }
}

@media (max-width: 768px) {
    .photonly {
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {
    .photonly {
        font-size: 1.25rem;
    }
}


.bookEvent {
    width: 100vw;
    max-width: 90rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2em 1em;
    background-color: #f9f9f9;
}

.bookEventContent {
    flex: 1;
    padding: 1em;
}

.bookEventHeader {
    margin-bottom: 1em;
    font-size: 3em;
}

.bookEventForm {
    display: flex;
    flex-direction: column;
}

.bookEventInput {
    margin-bottom: 2em;
    padding: 2.5em;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.bookEventButton {
    margin-top: 1rem;
    padding: 2em 1em;
    font-size: 1em;
    color: white;
    background-color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.bookEventImage {
    object-fit: cover;
    flex: 1;
    width: auto;
    height: 50rem;
    padding: 1em;
}

@media (max-width: 768px) {
    .bookEvent {
        flex-direction: column;
    }

    .bookEventContent, .bookEventImage {
        max-width: 100%;
    }
}
