#works {
    margin: 0 auto;
    margin-bottom: 4rem;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 90rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 55rem;
    margin-bottom: 2rem;
}

.worksImgs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 110rem;
}

.worksImg {
    border-radius: 0.7rem;
    object-fit: cover;
    height: 25rem;
    width: 30rem;
    margin: 0.95rem;
    transition: 0.3s;
}


.worksImg:hover {
    transform: scale(1.10);
    transition: 0.3s;
    border: 0.25rem solid #fff;
    cursor: pointer;
}

.worksBtn {
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: #8d8c8c;
    font-size: 1rem;
    box-shadow: 3px 5px 7px rgb(0,0,0,0.5);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.worksBtn:active {
    opacity: 0;
    color: #fff;
    transition: opacity 0.2s, transform 0.2s;
}

.worksBtn::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
}

.worksBtn:hover {
    gap: 1.5rem;
    transform: scale(1.05);
    transition: 0.3s;
}

@media screen and (max-width:680px) {
    .worksImg {
        height: 45vw;
    }
}