#services {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.services-title {
    margin: 1rem 0;
    font-size: 3rem;
    
}

.services-title h1 {
    padding: 1.875rem;
    font-size: 3rem;
}

.service-format {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25rem;
    padding: 5rem;
    border-radius: 0.625rem;
    border: 2px solid black;
    transition: 0.4s;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100vw;
    gap: 2.5rem;
}

.service-format h3{
    font-size: 0.9rem;
    font-weight: 600;
}

.service-format h2 {
    font-size: 1.7rem;
    font-weight: 800;
    background: linear-gradient(270deg, #526D82 50%, #27374D 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.service-format p {
    color: #000;
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 20rem;
}

.arrow-icon {
    object-fit: cover;
    width: 1.5rem;
    height: 1.5rem;
}

.service-readmore {
    display: flex;
    gap: 0.9375rem;
    align-items: center;
    margin-top: 1.5rem;
    border: 0.125rem solid #000;
    font-size: 1.35rem;
    font-weight: 500;
    border-radius: 3rem;
    background: #8d8c8c;
    padding: 0 1rem;
    cursor: pointer;
    width: 11rem;
    height: 3rem;
    box-shadow: 3px 5px 7px rgb(0,0,0,0.5);
    transition: transform 0.2s ease-in-out;
}

.service-readmore:hover {
    gap: 1.85rem;
    transition: 0.6s;
}

.service-readmore:active {
    opacity: 0;
    border-radius: #fff;
    color: #fff;
    transition: opacity 0.2s, transform 0.2s;
}

.service-readmore::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
}

.service-format:hover {
    border: 2px solid #FFF8EA;
    background: linear-gradient(45deg, #959393, #d2d2d2);
    transform: scale(1.05);
    transition: 0.4s;
}

@media screen and (max-width:530px) {
    .service-format>p {
    font-size: 3vw;    
    }
}

@media screen and (max-width:515px) {
    .services-container {
        height: 2.25rem;
        width: 2.25rem;
    }
}