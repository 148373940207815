/* dashboard.css */

/* General styles for the section */
.dashboard-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f0f0f0; /* Light grey background */
    padding: 20px;
    box-sizing: border-box;
}

/* Styles for the button */
.get-started-button {
    background-color: #000; /* Primary blue color */
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

/* Hover effect for the button */
.get-started-button:hover {
    background-color: #8d8c8c; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly larger */
}

/* Responsive styles */
@media (max-width: 768px) {
    .dashboard-section {
        padding: 10px;
    }

    .get-started-button {
        padding: 10px 20px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .get-started-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}
