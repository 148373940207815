#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 95rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.skillTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.skillDesc {
    font-weight: 400;
    font-size: 1rem;
    max-width: 65rem;
    padding: 0 2rem;
}

.skillBars {
    margin: 1rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.skillBar:hover {
    transform: scale(1.05);
    transition: 0.5s;
    cursor: pointer;
}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: #8d8c8c;
}

.skillBarImg {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
}

.skillBarTxt>p {
    font-size: 0.9rem;
    font-weight: 200;
}

@media screen and (max-width:720px) {
    .skillBarTxt>p {
        font-size: 2vw;
    }    
}


@media screen and (max-width:480px) {
    .skillBarTxt>p {
        font-size: 3vw;
    }    
    .skillBarImg {
        height: 2.25rem;
        width: 2.25rem;
    }
    .skillBarTxt>h2 {
        font-size: 5vw;
    }
}