.videography-section {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.video-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    z-index: 1;
}

.video-headings {
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 3rem;
}

.video-headings h3 {
    margin: 0;
    letter-spacing: 5px;
}

h1 {
    font-size: 4rem;
    letter-spacing: 3px;
    margin-bottom: 3rem;
}

.video-headings span {
    margin: 0 5rem;
    color: #FF0000;
}

.video-content p {
    margin-top: 1rem;
    font-size: 1.5rem;
}

.video-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.video-buttons button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    font-weight: 300;
    transition: background-color 0.3s ease;
}

.video-buttons button:hover {
    background-color: #ffffff49;
    opacity: 1;
    font-weight: 600;
}

/* Responsive styles */
@media (max-width: 768px) {
    .video-headings {
        flex-direction: column;
    }

    .video-content p {
        font-size: 1.2rem;
    }

    .video-buttons {
        flex-direction: column;
        gap: 0.5rem;
    }
}


.info-section {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    background-color: #f9f9f9;
    letter-spacing: 1px;
}

.info-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.info-image {
    flex: 1 1 40%;
    max-width: 40%;
    height: auto;
}

.info-text {
    flex: 1 1 55%;
    max-width: 55%;
    padding: 20px;
}

.info-text .info-span {
    display: block;
    width: 50px;
    height: 5px;
    background-color: #FF0000;
    margin-bottom: 10px;
}

.info-text h3 {
    margin: 10px 0;
    font-size: 1.8rem;
}

.info-text p {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .video-content h1 {
        font-size: 1.5rem;
    }

    .video-buttons {
        flex-direction: column;
    }

    .info-section {
        flex-direction: column;
    }

    .info-image,
    .info-text {
        flex: 1 1 100%;
        max-width: 100%;
    }
}


.filming-services-section {
    position: relative;
    padding: 50px;
    background: url('../../../assests/bg-6.jpg') no-repeat center center/cover;
    background-size: cover;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    letter-spacing: 0.5px;
}

.filming-services-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(88, 88, 88, 0.5); /* Adjust the opacity as needed */
    z-index: 1;
}

.filming-services-section > * {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 800px;
}

.intro {
    text-align: center;
    margin-bottom: 30px;
}

.intro h1 {
    color: #000;
    font-size: 2.5em;
    margin-bottom: 10px;
    font-weight: 600;
}

.intro p {
    width: 43rem;
    text-align: center;
    margin: 0 auto;
}

.short-line {
    display: block;
    width: 100px;
    height: 4px;
    background-color: #FF0000;
    margin: 1rem auto 1rem auto;
}

.service-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    text-align: center;
    padding: 1rem;
}

.service-item span {
    margin-bottom: 10px;
}

.service-item .service-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.service-item h4 {
    margin: 0 auto;
    color: #000;
    font-size: 1.2em;
}

.service-item p {
    margin: 0;
    text-align: center;
    width: 27rem;
    margin: 0 auto;
}

.all-in-all {
    max-width: 80rem;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
}


@media (max-width: 1200px) {
    .intro p {
        width: 35rem;
    }
    
    .service-item p {
        width: 25rem;
    }
}

@media (max-width: 992px) {
    .intro p {
        width: 30rem;
    }

    .service-item p {
        width: 22rem;
    }

    .all-in-all {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .intro h1 {
        font-size: 2em;
    }

    .intro p {
        width: 25rem;
    }

    .short-line {
        width: 75px;
    }

    .service-item {
        flex-direction: column;
        gap: 1rem;
    }

    .service-item .service-image {
        max-width: 100%;
        height: auto;
    }

    .service-item h4 {
        font-size: 1.1em;
    }

    .service-item p {
        width: 20rem;
    }

    .all-in-all {
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 576px) {
    .intro h1 {
        font-size: 1.5em;
    }

    .intro p {
        width: 20rem;
    }

    .short-line {
        width: 50px;
    }

    .service-item h4 {
        font-size: 1em;
    }

    .service-item p {
        width: 18rem;
    }
}


.video-container {
    position: relative;
    height: 100vh;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
}

.video-headings {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-headings h3 {
    margin: 0 10px;
}

.video-buttons {
    margin-top: 20px;
}

.info-section,
.filming-services-section,
.featured-films-section {
    padding: 40px 20px;
}

.featured-films-section {
    background: #fff;
    width: 100vw;
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.info-content,
.all-in-all {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-image {
    max-width: 50%;
    height: auto;
}

.info-text {
    max-width: 50%;
    margin-left: 20px;
}

.service-item {
    text-align: center;
    margin-bottom: 20px;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

.video-thumbnail {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
}


.thumbnail-video {
    width: 32rem;
    height: 22rem;
    display: block;
    object-fit: cover;
    margin: 0.2rem;
    transition: all 0.3s ease;
}

.video-thumbnail:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: rotate(-3deg) scale(1.05);
    opacity: 1;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    background: #fff;
    padding: 5px;
}

.modal-video {
    width: 100%;
    height: auto;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
}

.container  h2{
    font-weight: 700;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 7rem;
    padding: 1rem;
}

.container span {
    display: block;
    width: 68px;
    height: 3px;
    background-color: #FF0000;
    margin-bottom: 0px;
    margin-left: 8rem;
}

.container p {
    font-weight: 400;
    text-align: inherit;
    font-size: 16px;
    line-height: 26px;
    word-spacing: 0px;
    letter-spacing: 0px;
    display: block;
    width: 50rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 0;
    margin-left: 7rem;
}



/* Responsive styles */
@media (max-width: 1200px) {
    .video-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .info-content,
    .all-in-all {
        flex-direction: column;
        text-align: center;
    }

    .info-image,
    .info-text {
        max-width: 100%;
        margin: 0;
    }

    .container h2,
    .container p {
        margin-left: 0;
        width: 100%;
        text-align: center;
    }

    .container span {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 480px) {
    .video-grid {
        grid-template-columns: 1fr;
    }

    .thumbnail-video {
        width: 100%;
        height: auto;
    }
}



.workout {
    width: 100vw;
    max-width: 100%;
    background: #ff2d2d;
    margin: 0 auto;
    padding: 6rem;
    margin-bottom: 5rem;
}

.work-msg {
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.work-msg h6 {
    font-size: 1.1rem;
    padding-bottom: 1rem;
    margin-top: 0;
    margin-bottom: 0;
}

.work-msg h4 {
    font-size: 3rem;
    font-weight: bold; 
}

.work-msg a {
    color: #000;
    text-decoration: line-through;
}