
/* Navigation Bar */

.navbar {
    background: #cfcdcd;
    height: 5rem;
    width: 100vw;
    max-width: 120rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}


.active {
    color: #000;
    font-weight: bold;
    margin: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #000;
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 1.8rem;
    cursor: pointer;
}

.navMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: #fff;
    border-radius: 1rem;
    cursor: pointer;
}

.listItem {
    color: #000;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: #8d8c8c;
}


@media screen and (max-width:920px) {
    .mobMenu {
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
    button.desktopMenuBtn {
        display: none;
    }
}


/* Navigation Logo */

.logo {
    object-fit: cover;
    height: 5rem;
    width: 6rem;
}

/* Navigation Links */

.desktopMenuListItem {
    color: #000;
    margin: 2rem;
    cursor: pointer;
    transition: 0.6s;
}

.desktopMenuListItem:hover {
    gap: 1.25rem;
    transform: scale(1.05);
    transition: 0.6s;
}

.desktopMenuListItem:hover {
    color: #333333;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #333333;
}

/* contact button */

.desktopMenuBtn {
    background: #fff;
    color: #000;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
    cursor: pointer;
    box-shadow: 3px 5px 7px rgb(0,0,0,0.5);
    border: 3px solid #fff;
    transition: transform 0.2s ease-in-out;
}

.desktopMenuBtn:active {
    opacity: 0;
    color: #fff;
    transition: opacity 0.2s, transform 0.2s;
}

.desktopMenuBtn::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
}

.desktopMenuBtn:hover {
    transform: scale(1.05);
    transition: 0.3s;
}

 /*Contact Image  */

.desktopMenuImg {
    object-fit: contain;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
}