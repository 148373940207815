form {
    text-align: center;
}

label {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.0625rem solid #333;
    border-radius: 50%;
    margin: 0.625rem auto;
    line-height: 2.5rem;
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
    position: relative;
    cursor: pointer;
}

label span {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 2.5rem;
    pointer-events: none;
}

label:hover {
    background: #000;
    color: #fff;
}

label input[type="file"] {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}

.output {
    margin-top: 1rem;
    height: 3.75rem;
    font-size: 0.8rem;
}

.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

/* progress bar styles */
.progress-bar {
    height: 0.3125rem;
    background: #fff;
    margin-top: 1.25rem;
}

/* Responsive styles */
@media (max-width: 768px) {
    .output {
        font-size: 0.7rem;
        height: auto;
    }

    .error {
        font-size: 0.75rem;
    }

    .progress-bar {
        margin-top: 1rem;
    }
}
