#galleries {
    max-width: 100rem;
    margin: 0 auto;
    padding: 1rem; /* Add some padding */
}

#galleries h2, #galleries p {
    text-align: center;
}

#galleries h2 {
    margin-top: 3.75rem;
    font-size: 2.6rem;
}

/* Responsive styles */
@media (max-width: 768px) {
    #galleries {
        padding: 0.5rem; /* Adjust padding */
    }

    #galleries h1 {
        font-size: 1rem;
    }

    #galleries h2 {
        font-size: 2rem;
        margin-top: 2rem;
    }
}
