.footer {
    width: 100vw;
    height: 4rem;
    text-align: center;
    background: #8d8c8c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
}