/* image grid styles */
.img-grid {
    margin: 1.25rem auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Set three columns */
    grid-gap: 1rem; /* Adjust gap for better spacing */
}

.img-wrap {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* Aspect ratio 1:1 for square images */
    overflow: hidden;
}

.img-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area */
    cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
    .img-grid {
        grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
        grid-gap: 0.5rem; /* Adjusted gap for mobile view */
    }
}

@media (max-width: 480px) {
    .img-grid {
        grid-template-columns: 1fr; /* One column for mobile */
        grid-gap: 0.5rem; /* Adjusted gap for mobile view */
    }
}
